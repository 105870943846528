@import '../../components/glassTheme/Common.scss';

.matchesControls {
  width: 100%;
  height: 100%;
}

.matches {
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.matchBounds {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchError {
  @extend .raised-glass-bubble;
  width: 60%;
  height: 100px;
  box-sizing:border-box;
  text-align:center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 10;
}

.searchErrorIcon {
  @extend .glass-element-subtle;
  width: 30px;
  height: 30px;
}

.favourite-icon {
  color: white;
  width: 20px;
  height: 20px;
  margin: 2px;
}

.paginationTracker {
  @extend .glass-caption;
  @extend .italic;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  gap: 20px;
  padding: 0px 0px 20px 50px;
}

.paginationTracker-progressBar {
  width: 200px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  overflow: hidden;
}
.paginationTracker-progress {
  height: 100%;
  background-color: white;
  transition: none;
}

.paginationTracker-progress.shrinking {
  transition: width 0.1s ease-in;
}

.paginationTracker-pageInfo {
  @extend .glass-body;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100px;
}